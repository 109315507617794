html {
  box-sizing: border-box;
  font-size: 16px;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Mono', sans-serif;
}

h1 {
  margin-bottom: 0;
}
