.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;

  .headshot {
    width: 200px;
    border-radius: 50%;
  }

  .bio {
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
