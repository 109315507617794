.nav {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: black;

    + a {
      margin-left: 20px;
    }
  }

  .logo-wrapper {
    cursor: pointer;

    &:hover {
      .logo-k {
        margin-left: 0px;
        transition-delay: .5s;
      }

      .letters {
        width: 45px;
        opacity: 1;
        transition-delay: .5s;
      }
    }

    .logo-m,
    .logo-k {
      height: 50px;
    }

    .logo-k {
      margin-left: -35px;
      transition: margin-left .5s ease-in-out;
    }

    .letters {
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 8px;
      margin-left: 10px;
      width: 0px;
      opacity: 0;
      overflow: hidden;
      transition: all .5s;
      display: inline-block;
    }
  }

  .left-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }

  .right-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
  }
}
