main.fragment {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 600px;
  }

  .date {
    color: #cccccc;
    font-style: italic;
  }
}
