main.fragments {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    color: black;
  }

  .fragment {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .date {
      color: #cccccc;
      font-style: italic;
      margin-top: .25rem;
    }
  }

  h1 {
    margin-bottom: 1rem;
  }
}
